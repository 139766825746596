
.control-input-ui-addmember {
    border: 1px solid #BBBBBB !important;
    border-radius: 14px !important;
    color: #343434 !important;
    font-size: 18px !important;
    background-color: #fdfdfd !important;
    padding: 8px 12px !important;
}
.control-input-ui-addmember:disabled{
    background-color: #fff !important;
    border: 1px solid #fff !important;
    font-size: 20px !important;
    padding: 1px 0px !important;
    line-height: 1.2;
    font-weight: 600;
    resize: none;
}
.control-input-ui-addmember::placeholder {
    font-size: 17px !important;
}

.expandable-title {
    font-size: 18px;
    color: #03335b;
    font-weight: 600;
    line-height: 1.2;
    background-color: #f1f5fc;
    width: 100%;
    display: block;
    padding: 8px 10px;
    border-radius: 6px;
}

.expandable-title:hover {
    background-color: #1279be ;
    color: #fff;
}

.expandable-title.active{
    background-color: #1279be ;
    color: #fff;
}

.messageDivNew {
    background-color: transparent !important;
    color: green !important;
    padding: 0px !important;
    margin:0px!important;
    margin-top: 5px!important;
    font-weight: 400 !important;
    margin-bottom: 5px!important;
    border-radius: 10px!important;
    border: 1px solid transparent !important;
  }

.expandable-title-new {
    font-size: 18px;
    color: #03335b;
    font-weight: 600;
    line-height: 1.2;
    background-color: #f1f5fc;
    width: 100%;
    display: block;
    padding: 8px 10px;
    border-radius: 6px;
}

.addmember-scroll {
    height: 53vh;
    overflow-y: scroll;
}



.expandable-title-inner-content {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    line-height: 1.2;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.expandable-title-inner-button{
    border: 0;
    background-color: transparent;
    font-size: 16px;
    color: #333;
    font-weight: 600;
    width: 100%;
}

.form-check-label-expandeable{
    font-size: 14px;
    color: #333;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    align-items: center;
    width: 100%;
}
.checkbox-fl1{
    margin-top: 0.5rem;
    border: 1px solid #DCDCDC;
    padding: 0.5rem;
    border-radius: 8px;
}
/* .checkbox-fl1:nth-child(2){
    margin-top: 0.5rem;
    border-top: 1px solid #DCDCDC;
    padding-top: 0.5rem;
} */

.boxEducation #externalMemberSearch{
    --bs-modal-width: 94% !important;
}

.selected-checkbox {
    background-color: #03335b !important; /* Change to your preferred color */
    border: none; /* Optional: removes the default checkbox border */
    outline: none; /* Optional: removes outline */
    position: relative;
}
  
.selected-checkbox::after {
content: '';
position: absolute;
bottom: 44%;
left: 0;
width: 75%;
height: 2px;
background-color: #ffffff;
margin-left: 10%;
}
  
.blur-modal{
    filter: blur(0.5px);
}
  
.modulepups{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 100%;
    z-index: 1040 !important;
    background-color: hsl(0deg 0% 60.78% / 57%) !important;
    /* position: absolute;
    left: 0;
    right: 0; */
    
}

.div-10p{
    --bs-modal-width: 100%;
    --bs-modal-margin: 0rem;
    padding-right: 0px;
}

.modal-content-div-1 {
    width: 90%;
    border: 0  !important;
    margin: 0 auto;
    left: 0;
    right: 0;
}

#showListDataTable .modal-content {
    border: 0;
}

.badge-bg-completed{
    background-color: #5CB90D;
    padding: 10px 10px;
    font-size: 13px;
    font-weight: 500;
}

.badge-bg-inprogress{
    background-color: #E8BC00;
    padding: 10px 10px;
    font-size: 13px;
    font-weight: 500;
}

.badge-bg-pending{
    background-color: #DE460E;
    padding: 10px 10px;
    font-size: 13px;
    font-weight: 500;
}